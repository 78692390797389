<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import DepartmentService from "@/services/DepartmentService";
import VFilters from "@/components/Search/VFilters";
import MessagesMixin from "@/mixins/Messages";
import TaskService from "@/services/TaskService";
import VMissingDays from "@/views/Reports/components/VMissingDays";

export default {
    name: "VPersonalMultiview",
    mixins: [DateRangeMixin, MessagesMixin],
    components: {
        VFilters,
        DateRangePicker,
        VMissingDays,
    },
    data() {
        return {
            loading: true,
            stats: [],
            absence: {},
            showModal: false,
            context: null,
            goals: [],
            noregs: [],
            empty: [],
            deps: [],
        };
    },
    computed: {
        currentExportUrl: function () {
            let url = new URL(
                location.protocol +
                    "//" +
                    location.host +
                    "/v1/tasks/personal-multiview-report"
            );
            url.searchParams.append("csv", 1);
            let params = this.$buildParams();

            for (let param in params) {
                if (param == "dateRange") {
                    url.searchParams.append(
                        param,
                        JSON.stringify(params[param])
                    );
                } else {
                    if (Array.isArray(params[param])) {
                        params[param].forEach(function (value) {
                            url.searchParams.append(param + "[]", value);
                        });
                    } else {
                        url.searchParams.append(param, params[param]);
                    }
                }
            }

            return url.href;
        },
    },
    async created() {
        this.resetSearch();
        this.$apiEnableWatcher();
        await this.fetchDepartments(true);
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            if (this.apiSearchFilter.dateRange.startDate === null) {
                this.loading = false;
                return;
            }

            try {
                this.loading = true;
                let params = this.$buildParams();
                const { data } = await TaskService.GET_personalMultiviewReport(
                    params
                );
                this.absence = data.data.absence;
                this.stats = data.data.stats;
                this.noregs = data.data.noregs;
                this.goals = data.data.goals;
                this.empty = data.data.users_empty;
            } catch (e) {
                console.log("e fetchData", e);
            }

            this.loading = false;
        },
        async fetchDepartments(loading = false) {
            try {
                this.loading = true;
                const r = await DepartmentService.dropdown();
                this.deps = r.data.data;
            } catch (e) {
                console.log("fetchDepartments e", e);
            }

            this.loading = loading;
        },
        resetSearch() {
            this.apiSearchFilter = {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            };
        },
        /**
         * Close details modal
         */
        closeDetails() {
            this.context = null;
            this.showModal = false;
        },
        /**
         * Open details modal with not clsoed days
         */
        showDetails(stat, noregs) {
            this.context = { stat, noregs };
            this.showModal = true;
        },
    },
};
</script>

<template>
    <div class="v-personal-multiview">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <!-- V-FILTERS -->
                        <div class="px-4 mb-4">
                            <v-filters
                                :search="false"
                                :start-open="true"
                                :enable-filters="true"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <!-- Default slot starts here -> filters -->
                                <div class="col-12 col-md-3">
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="right"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="apiSearchFilter.dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                        :ranges="getDateRangePickerRanges()"
                                    >
                                        <template #header>
                                            <manual-range
                                                :date-range-manual="
                                                    dateRangeManual
                                                "
                                                :date-range-error="
                                                    dateRangeError
                                                "
                                                @exec="
                                                    execManualRange(
                                                        'apiSearchFilter',
                                                        'dateRange'
                                                    )
                                                "
                                            />
                                        </template>
                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                </div>
                                <div class="col-12 col-md-3">
                                    <FormulateInput
                                        type="select"
                                        :options="deps"
                                        v-model="apiSearchFilter.department_id"
                                        :placeholder="
                                            $t(
                                                'stats.placeholder_select_department'
                                            )
                                        "
                                    />
                                </div>
                                <div
                                    class="col-12 col-md-3"
                                    v-if="stats.length > 0"
                                >
                                    <div style="margin-top: 14px">
                                        <a :href="currentExportUrl" class=""
                                            ><i class="fa fa-file-csv"></i>
                                            {{
                                                $t(
                                                    "personal_multiview.export_as_csv"
                                                )
                                            }}</a
                                        >
                                    </div>
                                </div>
                                <div
                                    class="col-12 mt--3"
                                    v-if="
                                        apiSearchFilter.dateRange.startDate ===
                                        null
                                    "
                                >
                                    <div class="text-bold text-warning">
                                        {{
                                            $t(
                                                "personal_multiview.warn_select_dates"
                                            )
                                        }}
                                    </div>
                                </div>
                            </v-filters>
                        </div>

                        <div class="px-4" v-if="empty.length > 0">
                            <h3>
                                {{ $t("personal_multiview.people_wo_regs") }}
                            </h3>
                            <ul>
                                <li v-for="ep in empty" :key="ep.user_id">
                                    {{ ep.name }}
                                </li>
                            </ul>
                        </div>

                        <!-- BODY -->
                        <v-loader :loading="loading" v-if="loading" />
                        <div v-else-if="stats.length > 0">
                            <v-table class="table-mg table table-sm">
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("personal_multiview.name")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t(
                                                "personal_multiview.customer_related_time"
                                            )
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t(
                                                "personal_multiview.internal_time"
                                            )
                                        }}</v-th>
                                        <v-th
                                            class="text-right"
                                            v-for="absence_key in Object.keys(
                                                absence
                                            )"
                                            :key="absence_key"
                                        >
                                            {{ absence[absence_key] }}
                                        </v-th>
                                        <v-th class="text-right">
                                            {{
                                                $t(
                                                    "personal_multiview.time_total"
                                                )
                                            }}
                                        </v-th>
                                        <v-th class="text-right">
                                            {{
                                                $t(
                                                    "personal_multiview.time_goal"
                                                )
                                            }}
                                        </v-th>
                                        <v-th class="text-right">
                                            {{
                                                $t(
                                                    "personal_multiview.time_balance"
                                                )
                                            }}
                                        </v-th>
                                        <v-th class="text-right">
                                            {{
                                                $t(
                                                    "personal_multiview.incomplete_days"
                                                )
                                            }}
                                        </v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr
                                        v-for="(stat, index) in stats"
                                        :key="index"
                                    >
                                        <v-td>{{ stat.name }}</v-td>
                                        <v-td class="text-right">{{
                                            stat.time_customers | toTime
                                        }}</v-td>
                                        <v-td class="text-right">{{
                                            stat.time_internal | toTime
                                        }}</v-td>
                                        <v-td
                                            class="text-right"
                                            v-for="absence_key in Object.keys(
                                                absence
                                            )"
                                            :key="absence_key"
                                        >
                                            {{ stat[absence_key] | toTime }}
                                        </v-td>
                                        <v-td class="text-right">
                                            {{ stat.time_total | toTime }}
                                        </v-td>
                                        <v-td class="text-right">
                                            <template
                                                v-if="
                                                    parseInt(stat.daily_goal) >
                                                    0
                                                "
                                            >
                                                {{ stat.time_goal | toTime }}
                                            </template>
                                            <template v-else>{{
                                                $t("users.paid_per_hour")
                                            }}</template>
                                        </v-td>
                                        <v-td class="text-right">
                                            <template
                                                v-if="
                                                    parseInt(stat.daily_goal) >
                                                    0
                                                "
                                            >
                                                <span
                                                    :class="
                                                        parseInt(
                                                            stat.time_balance
                                                        ) < 0
                                                            ? 'text-danger'
                                                            : ''
                                                    "
                                                >
                                                    <template
                                                        v-if="
                                                            parseInt(
                                                                stat.time_balance
                                                            ) < 0
                                                        "
                                                        >-</template
                                                    >
                                                    {{
                                                        stat.time_balance
                                                            | toTime
                                                    }}
                                                </span>
                                            </template>
                                            <template v-else>{{
                                                $t("users.paid_per_hour")
                                            }}</template>
                                        </v-td>
                                        <v-td class="text-right">
                                            <template v-if="false">
                                                ---
                                            </template>
                                            <template v-else>
                                                <span
                                                    class="m-click"
                                                    @click="
                                                        showDetails(
                                                            stat,
                                                            noregs[stat.user_id]
                                                        )
                                                    "
                                                >
                                                    <i class="fa fa-search"></i>
                                                    <span
                                                        class="ml-2 text-bold"
                                                        >{{
                                                            goals[stat.user_id]
                                                        }}</span
                                                    >
                                                </span>
                                            </template>
                                        </v-td>
                                        <v-td v-if="false">{{ stat }}</v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                        </div>
                        <div class="px-4" v-else>
                            <div class="alert alert-warning">
                                {{ $t("personal_multiview.no_results_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-missing-days
                :context="context"
                v-if="showModal"
                @close="closeDetails"
            />
        </portal>
    </div>
</template>


<style>
</style>
