var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-personal-multiview"},[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag')],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[_c('div',{staticClass:"px-4 mb-4"},[_c('v-filters',{attrs:{"search":false,"start-open":true,"enable-filters":true},on:{"reset-search":_vm.resetSearch,"filter-view-change":function($event){return _vm.$handleFilterView($event)}}},[_c('div',{staticClass:"col-12 col-md-3"},[_c('date-range-picker',{ref:"picker",staticClass:"range-picker",attrs:{"opens":"right","timePicker":false,"showWeekNumbers":true,"autoApply":true,"linkedCalendars":true,"locale-data":_vm.getDateRangePickerLocale(),"ranges":_vm.getDateRangePickerRanges()},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('manual-range',{attrs:{"date-range-manual":_vm.dateRangeManual,"date-range-error":_vm.dateRangeError},on:{"exec":function($event){return _vm.execManualRange(
                                                    'apiSearchFilter',
                                                    'dateRange'
                                                )}}})]},proxy:true},{key:"input",fn:function(picker){return [(picker.startDate)?[_vm._v(" "+_vm._s(_vm.$d( picker.startDate, "short" ))+" - "+_vm._s(_vm.$d(picker.endDate, "short"))+" ")]:_vm._e()]}}]),model:{value:(_vm.apiSearchFilter.dateRange),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "dateRange", $$v)},expression:"apiSearchFilter.dateRange"}})],1),_c('div',{staticClass:"col-12 col-md-3"},[_c('FormulateInput',{attrs:{"type":"select","options":_vm.deps,"placeholder":_vm.$t(
                                            'stats.placeholder_select_department'
                                        )},model:{value:(_vm.apiSearchFilter.department_id),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "department_id", $$v)},expression:"apiSearchFilter.department_id"}})],1),(_vm.stats.length > 0)?_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{staticStyle:{"margin-top":"14px"}},[_c('a',{attrs:{"href":_vm.currentExportUrl}},[_c('i',{staticClass:"fa fa-file-csv"}),_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.export_as_csv" )))])])]):_vm._e(),(
                                    _vm.apiSearchFilter.dateRange.startDate ===
                                    null
                                )?_c('div',{staticClass:"col-12 mt--3"},[_c('div',{staticClass:"text-bold text-warning"},[_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.warn_select_dates" ))+" ")])]):_vm._e()])],1),(_vm.empty.length > 0)?_c('div',{staticClass:"px-4"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("personal_multiview.people_wo_regs"))+" ")]),_c('ul',_vm._l((_vm.empty),function(ep){return _c('li',{key:ep.user_id},[_vm._v(" "+_vm._s(ep.name)+" ")])}),0)]):_vm._e(),(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):(_vm.stats.length > 0)?_c('div',[_c('v-table',{staticClass:"table-mg table table-sm"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t("personal_multiview.name")))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "personal_multiview.customer_related_time" )))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "personal_multiview.internal_time" )))]),_vm._l((Object.keys(
                                            _vm.absence
                                        )),function(absence_key){return _c('v-th',{key:absence_key,staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.absence[absence_key])+" ")])}),_c('v-th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.time_total" ))+" ")]),_c('v-th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.time_goal" ))+" ")]),_c('v-th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.time_balance" ))+" ")]),_c('v-th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t( "personal_multiview.incomplete_days" ))+" ")])],2)],1),_c('v-tbody',_vm._l((_vm.stats),function(stat,index){return _c('v-tr',{key:index},[_c('v-td',[_vm._v(_vm._s(stat.name))]),_c('v-td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("toTime")(stat.time_customers)))]),_c('v-td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("toTime")(stat.time_internal)))]),_vm._l((Object.keys(
                                            _vm.absence
                                        )),function(absence_key){return _c('v-td',{key:absence_key,staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toTime")(stat[absence_key]))+" ")])}),_c('v-td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("toTime")(stat.time_total))+" ")]),_c('v-td',{staticClass:"text-right"},[(
                                                parseInt(stat.daily_goal) >
                                                0
                                            )?[_vm._v(" "+_vm._s(_vm._f("toTime")(stat.time_goal))+" ")]:[_vm._v(_vm._s(_vm.$t("users.paid_per_hour")))]],2),_c('v-td',{staticClass:"text-right"},[(
                                                parseInt(stat.daily_goal) >
                                                0
                                            )?[_c('span',{class:parseInt(
                                                        stat.time_balance
                                                    ) < 0
                                                        ? 'text-danger'
                                                        : ''},[(
                                                        parseInt(
                                                            stat.time_balance
                                                        ) < 0
                                                    )?[_vm._v("-")]:_vm._e(),_vm._v(" "+_vm._s(_vm._f("toTime")(stat.time_balance))+" ")],2)]:[_vm._v(_vm._s(_vm.$t("users.paid_per_hour")))]],2),_c('v-td',{staticClass:"text-right"},[(false)?[_vm._v(" --- ")]:[_c('span',{staticClass:"m-click",on:{"click":function($event){return _vm.showDetails(
                                                        stat,
                                                        _vm.noregs[stat.user_id]
                                                    )}}},[_c('i',{staticClass:"fa fa-search"}),_c('span',{staticClass:"ml-2 text-bold"},[_vm._v(_vm._s(_vm.goals[stat.user_id]))])])]],2),(false)?_c('v-td',[_vm._v(_vm._s(stat))]):_vm._e()],2)}),1)],1)],1):_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("personal_multiview.no_results_found"))+" ")])])],1)],1)])]),_c('portal',{attrs:{"to":"modals"}},[(_vm.showModal)?_c('v-missing-days',{attrs:{"context":_vm.context},on:{"close":_vm.closeDetails}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }